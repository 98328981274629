import React from 'react';
import Image from 'reusecore/src/elements/Image';
import { Link } from 'gatsby';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import add_google_account from '../../../../../../../common/src/assets/image/2019/add_google_account.png';
import google_allow_access from '../../../../../../../common/src/assets/image/2019/google_allow_access.png';
import add_google_calendars from '../../../../../../../common/src/assets/image/2019/add_google_calendars.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal can create printable calendars containing events <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/Google-Tasks">and tasks</Link> from Google Calendar in Excel, Word, and PowerPoint. Rather than being limited to the few layouts and very limited settings provided by Google, you can choose from
        over <Link to="/Documentation/Create-Calendar/Choose-a-Template">90 different calendar templates and layouts</Link>, which can also be <Link to="/Documentation/How-To/Customize-a-Template">customized</Link> with your own logo, contact
        information, or just about anything else you'd like to include.
      </p>
      <p>To include Google Calendar events in your printable calendars, follow these steps:</p>
      <ol className="numbered">
        <li>
          Click the <strong>Add Calendar</strong> button and select{' '}
          <strong>Online -&gt; Google Calendar</strong>.
        </li>
        <li>
          A window will appear where you can select a previously connected
          account, or add a connection to a new account. To add a connection,
          enter your account name and click the Next button.&nbsp;
          <br />
          <Image alt="" src={add_google_account} style={{ width: 737 }} />
        </li>
        <li>
          A web page will appear where you can confirm your account selection.
          After confirming, click the "Allow" button.
          <br />
          <Image alt="" src={google_allow_access} style={{ width: 479 }} />
        </li>
        <li>
          After allowing access, the list of calendars in your account will
          appear. Use the check boxes to select which calendars should be added
          to PrintableCal.
          <br />
          <Image alt="" src={add_google_calendars} style={{ width: 737 }} />
        </li>
        <li>
          Click the <strong>OK</strong> button when you're done selected the
          desired calendars.
        </li>
      </ol>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Calendar-Data-Sources/Google-Calendar"
      commentsId="commentsplus_post_137_489"
      title="A Better Way to Print Google Calendar"
      description="PrintableCal can create printable calendars containing events from Google Calendar."
      keywords="print Google Calendar, print Google events, import Google Calendar, printable calendar, print calendar, print events, import events, 2020 calendar, Excel calendar, Word calendar"
      content={content}
    />
  );
};

export default Documentation;
